import React from "react"
import PropTypes from "prop-types"

import HTBS from "../components/htbs"

import "../css/landing_sequence.css"

function LandingSequence({ shouldHide }) {
  return (
    <div className="landing-sequence">
      <div className="landing-sequence__wrapper">
        <HTBS />
      </div>
    </div>
  )
}

LandingSequence.propTypes = {
  shouldHide: PropTypes.bool,
}
LandingSequence.defaultProps = {
  shouldHide: false,
}

export default LandingSequence
