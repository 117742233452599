import { injectIntl } from "gatsby-plugin-intl"
import React from "react"
import together from "../images/together.svg"
import d4c from "../images/d4c.svg"
import twitterBtn from "../images/twitter.svg"
import facebookBtn from "../images/facebook.svg"
import emailBtn from "../images/envelop.svg"

const Footer = ({ intl }) => {
  return (
    <div className="container bg-white mx-auto px-4">
      <div className="flex flex-wrap">
        {/* box 1 */}
        <div className="brdrLine1 flex flex-wrap w-full pb-4 sm:w-1/4 md:w-1/5">
          <div className="w-2/3 sm:w-full">
            <p className="footerText px-1 pl-0">
              {intl.formatMessage({ id: "footer.togetherd4c" })}
            </p>
          </div>

          <div className="w-1/3 sm:w-full">
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.together-et.com/"
              className="flex-1"
            >
              <img
                src={together}
                className="h-8 align-center ml-2 sm:ml-0"
                alt={intl.formatMessage({ id: "nav.alt.together" })}
              />
            </a>
          </div>
        </div>

        {/* box 2 */}
        <div className="brdrLine flex flex-wrap w-full pb-4 sm:w-1/4 md:w-1/5">
          <div className="w-2/3 sm:w-full">
            <p className="footerText md:px-1">
              {intl.formatMessage({ id: "footer.togetherd4c2" })}
            </p>
          </div>

          <div className="w-1/3 sm:w-full">
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="http://data4chan.ge"
              className=""
            >
              <img
                src={d4c}
                className="h-20 align-center ml-4 sm:ml-0"
                alt={intl.formatMessage({ id: "nav.alt.d4c" })}
              />
            </a>
          </div>
        </div>

        {/* box 3 */}

        <div className="brdrLine w-full sm:w-1/2 md:w-2/5">
          <div className="w-full">
            <p className="footerText md:px-1">
              {intl.formatMessage({ id: "footer.together" })}{" "}
            </p>
          </div>
        </div>
        {/* box 4 and 5*/}

        <div className="brdrLine flex flex-wrap w-full md:w-1/5">
          <div className="w-1/2 sm:w-1/4 md:w-full md:px-1">
            <p className="footerText">
              {intl.formatMessage({ id: "follow" })}{" "}
            </p>

            <div className="flex flex-wrap w-full mt-4 md:px-1">
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.facebook.com/TogetherEthiopia/"
              >
                <img
                  src={facebookBtn}
                  alt={intl.formatMessage({ id: "nav.alt.facebook" })}
                  className="w-10 mr-1"
                />
              </a>

              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://twitter.com/Together_Eth"
              >
                <img
                  src={twitterBtn}
                  alt={intl.formatMessage({ id: "nav.alt.twitter" })}
                  className="w-10"
                />
              </a>
            </div>
          </div>
          <div className="w-1/2 sm:w-1/4 md:w-full md:mt-4 md:px-1">
            <p className="footerText">
              {intl.formatMessage({ id: "contact" })}{" "}
            </p>
            <a href="mailto:theoffice@together-et.com">
              {/* CHANGE TO EMAIL ICON IMAGE */}
              <img
                src={emailBtn}
                alt={intl.formatMessage({ id: "nav.alt.email" })}
                className="w-10 mt-4"
              />
            </a>
          </div>
        </div>
      </div>
    </div>
  )
}

export default injectIntl(Footer)
