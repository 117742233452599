import React, { Component } from "react"
import PropTypes from "prop-types"
import { injectIntl } from "gatsby-plugin-intl"

import Header from "./header"
import Footer from "./footer"
import LandingSequence from "./landing_sequence"

class Layout extends Component {
  constructor(props) {
    super(props)

    this.state = {
      shouldHideLanding: false,
      hasLanding:
        typeof window !== "undefined" && window.hasOwnProperty("isFirstRender")
          ? window.isFirstRender
          : true,
    }
  }
  render() {
    const { children, intl } = this.props
    const { shouldHideLanding, hasLanding } = this.state

    const langClassName = `lang-${intl.locale}`

    const renderedLandingSequence = hasLanding ? (
      <LandingSequence shouldHide={shouldHideLanding} />
    ) : (
      <div />
    )

    return (
      <div className={`${langClassName} container bg-white mx-auto px-4`}>
        {renderedLandingSequence}
        <Header />
        <div className="mx-auto px-4 py-8">
          <main>{children}</main>
        </div>
        <Footer />
      </div>
    )
  }
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default injectIntl(Layout)
