import { injectIntl } from "gatsby-plugin-intl"
import React from "react"
import Language from "./language"
import Nav from "./nav"

const Header = ({ intl }) => {
  return (
    <div className="flex flex-row-reverse mx-auto float-left md:w-3/4 lg:w-2/3">
      <Language />
      <Nav />
    </div>
  )
}

export default injectIntl(Header)
