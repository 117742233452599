import React from "react"
import { injectIntl } from "gatsby-plugin-intl"
import SEO from "../components/seo"
import htbs_en from "../images/htbs-en.svg"
import htbs_am from "../images/htbs-am.svg"

const HTBS = ({ intl }) => {
  let publicURL = intl.locale === "en" ? htbs_en : htbs_am

  return (
    <div className="w-full">
      <SEO
        lang={intl.locale}
        title={intl.formatMessage({ id: "title" })}
        keywords={[`Hear The Blind Spot`, `DestaStory`, `Together!`]}
      />
      <img
        src={publicURL}
        className="blur"
        alt={intl.formatMessage({ id: "nav.alt.htbs" })}
      />
    </div>
  )
}

export default injectIntl(HTBS)
