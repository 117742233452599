import { injectIntl, Link } from "gatsby-plugin-intl"
import React from "react"
import homebtn from "../images/home.svg"
import homebtn_active from "../images/home-active.svg"

import homebtn_am from "../images/home-amharic.svg"
import homebtn_am_active from "../images/home-amharic-active.svg"

import aboutbtn from "../images/about.svg"
import aboutbtn_active from "../images/about-active.svg"
import aboutbtn_am from "../images/about-amharic.svg"
import aboutbtn_am_active from "../images/about-amharic-active.svg"

import donatebtn from "../images/donate.svg"
import donatebtn_active from "../images/donate-active.svg"

import donatebtn_am from "../images/donate-amharic.svg"
import donatebtn_am_active from "../images/donate-amharic-active.svg"

const Nav = ({ intl }) => {
  let currentPath = typeof window !== "undefined" && window.location.pathname

  let homeEn = currentPath === "/en/" ? homebtn_active : homebtn
  let homeAm = currentPath === "/am/" ? homebtn_am_active : homebtn_am
  let aboutEn = currentPath === "/en/about" ? aboutbtn_active : aboutbtn
  let aboutAm = currentPath === "/am/about" ? aboutbtn_am_active : aboutbtn_am
  let donateAm =
    currentPath === "/am/donate" ? donatebtn_am_active : donatebtn_am
  let donateEn = currentPath === "/en/donate" ? donatebtn_active : donatebtn

  return (
    <div className="flex flex-wrap pl-4">
      <div className="strapLine w-full">
        <p className="uppercase pt-4">
          {intl.formatMessage({ id: "nav.strapline" })}
        </p>
      </div>

      <div className="navButtons flex flex-wrap items-stretch">
        <div>
          <Link to="/">
            <img
              src={intl.locale === "en" ? homeEn : homeAm}
              className={
                intl.locale === "am"
                  ? "h-10 sm:h-12 md:h-18 py-2 pr-1 sm:pr-8"
                  : "h-10 sm:h-12 md:h-18 py-2 pr-1 sm:pr-8"
              }
              alt={intl.formatMessage({ id: "nav.alt.desta" })}
            />
          </Link>
        </div>

        <div>
          <Link to="/donate">
            <img
              src={intl.locale === "en" ? donateEn : donateAm}
              className={
                intl.locale === "am"
                  ? "h-10 sm:h-12 md:h-18 py-2 pr-1 sm:pr-8"
                  : "h-10 sm:h-12 md:h-18 py-2 pr-1 sm:pr-8"
              }
              alt={intl.formatMessage({ id: "nav.alt.donate" })}
            />
          </Link>
        </div>

        <div>
          <Link to="/about" className="">
            <img
              src={intl.locale === "en" ? aboutEn : aboutAm}
              className={
                intl.locale === "am"
                  ? "h-10 sm:h-12 md:h-18 py-2 pr-1 sm:pr-8"
                  : "h-10 sm:h-12 md:h-18 py-2 pr-1 sm:pr-8"
              }
              alt={intl.formatMessage({ id: "nav.alt.about" })}
            />
          </Link>
        </div>
      </div>
    </div>
  )
}

export default injectIntl(Nav)
