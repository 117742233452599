import React from "react"
import { IntlContextConsumer, changeLocale } from "gatsby-plugin-intl"

const languageName = {
  en: "English",
  am: "በአማርኛ",
}

const Language = () => {
  return (
    <div className="flex flex-col justify-end py-2 px-1">
      <IntlContextConsumer>
        {({ languages, language: currentLocale }) =>
          languages.map(language => (
            <button
              key={language}
              onClick={() => changeLocale(language)}
              className={`font-bold langsel text-left lang-button-${language}`}
              aria-label={
                languageName[language] === "በአማርኛ"
                  ? "Amharic"
                  : languageName[language]
              }
              style={{
                color: currentLocale === language ? `black` : `grey`,
                textDecoration: `none`,
                cursor: `pointer`,
              }}
            >
              {languageName[language]}
            </button>
          ))
        }
      </IntlContextConsumer>
    </div>
  )
}

export default Language
